@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Playfair Display', sans-serif;
  color: #111111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

/* Fade Animation from Animate.css */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}