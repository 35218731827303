/**
 * `@property` is required for the animation to work.
 * Without it, the angle values won’t interpolate properly.
 *
 * @see https://dev.to/afif/we-can-finally-animate-css-gradient-kdk
 */
@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

/**
 * To animate the gradient, we set the custom property to 1 full
 * rotation. The animation starts at the default value of `0deg`.
 */
@keyframes spin {
  to {
    --bg-angle: 360deg;
  }
}


.gradient-border {
  /* add the animation, but pause it by default */
  animation: spin 7.5s infinite linear;

  /**
   * Using `background-origin` we can create a “border” using two gradients. And to
   * make the gradients better-looking, we use OKLCH.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background-origin
   * @see https://evilmartians.com/chronicles/oklch-in-css-why-quit-rgb-hsl
   */
  background:
    /* Background colors don’t work with `background-origin`, so use a gradient. */
    linear-gradient(to bottom,
      oklch(0.1 0.2 240 / 0.95),
      oklch(0.1 0.2 240 / 0.95)) padding-box,
    /* ends at inner border edges */
    conic-gradient(from var(--bg-angle) in oklch longer hue,
      oklch(0.85 0.37 0) 0 0) border-box;
  /* extends to outer border edges */

  /* a clear border lets the background gradient shine through */
  border: 6px solid transparent;

  /* unpause the animation on hover */
  /* &:hover {
    animation-play-state: running;
  } */
}

.gradient-bg {
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  /* animation: spin 3s ease alternate infinite; */
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;

  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}